<template>
  <div class="pass-wrapper">
    <div class="title">
      <div class="left">成果验收</div>
      <div class="right">
        <el-link icon="el-icon-back" type="primary" @click="$router.go(-1)">返回</el-link>
      </div>
    </div>
    <div class="main">
      <div class="base-info-group">
        <div class="avatar">
          <img :src="freelancer.avatar" alt="avatar" />
        </div>
        <div class="name">{{ freelancer.name }}</div>
        <div class="phone" v-if="freelancer.mobile">{{ freelancer.mobile }}</div>
      </div>
      <div class="dog-group">
        <div class="description">
          <p class="description-title">成果说明:</p>
          <p>{{ baseData.work_memo }}</p>
        </div>
        <div class="accessory" v-if="(baseData.attrs || []).length > 0">
          <div class="accessory-title">
            <h3>附件</h3>
            <!--            <div @click="downloadAll">-->
            <!--              <i></i>-->
            <!--              <span>全部下载</span>-->
            <!--            </div>-->
          </div>
          <div class="accessory-list">
            <a
              class="card"
              v-for="(item, index) in baseData.attrs"
              :key="index"
              :href="item.url"
              download
              target="_blank"
            >
              <div class="card-left">
                <i :class="`i-type-${item.ext || '7z'}`"></i>
              </div>
              <div class="card-right">
                <p class="tit">{{ item.name }}</p>
                <p class="size">{{ item.size_format }}</p>
              </div>
            </a>
          </div>
        </div>
        <div class="amount-status">
          <div class="left">
            <span class="label">应付金额</span>
            <span class="price"
              ><strong>{{ baseData.plan_salary_amount }}</strong
              >元</span
            >
          </div>
          <div class="right">
            <div class="btn-group">
              <div class="cannot" @click="cannotHandle" v-if="status === 4">不通过</div>
              <div class="pass" @click="passHandle" v-if="status === 4 || status === 5"
                >验收通过</div
              >
              <div class="reward" @click="rewardHandle" v-if="status === 6">发放报酬</div>
            </div>
            <div class="time" v-if="status === 9">{{ baseData.real_grant_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pass-log">
      <div class="log-title">验收日志</div>
      <div class="log-list-wrapper" v-if="(baseData.logs || []).length > 0">
        <div
          class="log-item"
          :class="{ 'hirer-reply': item.operator == '雇主' }"
          v-for="(item, index) in baseData.logs"
          :key="index"
        >
          <div class="row_1">
            <div class="line_1">
              <div class="time">{{ item.log_time }}</div>
              <div class="avatar">
                <img :src="item.avatar" alt="" />
              </div>
            </div>
            <div class="line_2">
              <div class="info">
                <span class="name">{{ item.operator }}</span>
              </div>
              <div class="status">{{ item.content }}</div>
            </div>
          </div>
          <div class="row_2">
            <div class="line_1"></div>
            <div class="line_2">
              <div class="comment">{{ item.operator == '雇主' ? item.work_memo : '' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="modalTwoConfig.title" :visible.sync="isShowModalTwo" width="480px">
      <div class="modal-two-content" v-html="modalTwoConfig.content"></div>
      <div class="modal-two-btn">
        <div class="cancel" @click="isShowModalTwo = false">取消</div>
        <div class="confirm" @click="modalTwoConfig.confirmHandle">确定</div>
      </div>
    </el-dialog>
    <el-dialog :title="'验收不通过？请写明原因'" :visible.sync="isShowModalThree" width="480px">
      <div class="modal-two-content"
        ><div class="cannot-pass">
          <textarea placeholder="告诉雇员如何修改工作成果" v-model="reason"></textarea></div
      ></div>

      <div class="modal-two-btn">
        <div class="cancel" @click="isShowModalThree = false">取消</div>
        <div class="confirm" @click="submitReasonHandle">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'index.vue',
    data() {
      return {
        type: 2,
        isShowModalTwo: false,
        isShowModalThree: false,
        reason: '',
        work_item_id: '',
        baseData: {},
        freelancer: {},
        status: ''
      };
    },
    created() {
      this.work_item_id = this.$route.query.id;
      this.getBaseData();
    },
    watch: {},
    computed: {
      modalTwoConfig() {
        if (this.type == 1) {
          return {
            title: '验收通过',
            content: `<div class="pass">为了保障您的合法权益，请在100%满意人才工作时，再通过工作成果验收。</div>`,
            confirmHandle: () => {
              this.$Http
                .post({
                  url: '/api/uc/task/work/check',
                  params: {
                    result: 'y',
                    work_item_id: this.work_item_id
                  }
                })
                .then(({ statusCode }) => {
                  this.isShowModalTwo = false;
                  statusCode == 200 &&
                    this.$notify({ type: 'success', message: '已验收' }) &&
                    setTimeout(() => {
                      this.$router.go(-1);
                    }, 1500);
                });
            }
          };
        }
        if (this.type == 2) {
          return {
            title: '发放报酬',
            content: `<div class="reward"><p>验收通过，是否立即发放报酬</p><p class="price">应付金额<span>500.00</span></p></div>`,
            confirmHandle: () => {
              this.$Http
                .post({
                  url: '/api/uc/task/work/grant',
                  params: {
                    work_item_id: this.work_item_id
                  }
                })
                .then(({ statusCode }) => {
                  this.isShowModalTwo = false;
                  statusCode == 200 && this.$notify({ type: 'success', message: '已发放' });
                });
            }
          };
        }
        return {
          title: '',
          content: ``,
          confirmHandle: () => {}
        };
      },
      communicationLogs() {
        return '';
      }
    },

    methods: {
      async submitReasonHandle() {
        const { statusCode } = await this.$Http.post({
          url: '/api/uc/task/work/check',
          params: {
            result: 'n',
            check_memo: this.reason,
            work_item_id: this.work_item_id
          }
        });
        this.isShowModalThree = false;
        if (statusCode == 200) {
          this.$notify({ message: '已提交', type: 'success' });
          setTimeout(() => {
            // location.reload();
            this.$router.go(-1);
          }, 1500);
        }
      },
      async getBaseData() {
        const { data } = await this.$Http.get({
          url: `/api/uc/task/work`,
          params: {
            work_item_id: this.work_item_id
          }
        });
        this.baseData = data;
        this.freelancer = data.freelancer;
        this.status = data.status;
      },
      cannotHandle() {
        this.isShowModalThree = true;
      },
      passHandle() {
        this.type = 1;
        this.isShowModalTwo = true;
      },
      rewardHandle() {
        this.type = 2;
        this.isShowModalTwo = true;
      },
      downloadAll() {
        const list = this.baseData.attrs;
        list.forEach((item) => {
          this.downLoad(item.url);
        });
      },
      downLoad(url) {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        setTimeout(() => {
          iframe.remove();
        }, 2 * 60 * 1000);
        // window.location.href = url;
      }
    }
  };
</script>

<style lang="less" scoped>
  .pass-wrapper {
    background-color: #fff;
    padding: 0 40px;
    min-height: 984px;
    .title {
      padding: 30px 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .main {
      padding: 20px 0;
      display: flex;
      .base-info-group {
        text-align: center;
        .avatar {
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        .name {
          margin-top: 20px;
        }
        .phone {
          font-size: 14px;
          color: #575757;
          margin-top: 10px;
        }
      }
      .dog-group {
        margin-left: 56px;
        flex: 1;
        .description {
          p {
            font-size: 14px;
            line-height: 24px;
            color: #575757;
          }
          .description-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
        .accessory {
          margin-top: 40px;
          .accessory-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
              font-size: 16px;
              font-weight: bold;
              line-height: 24px;
              color: #575757;
            }
            div {
              font-size: 14px;
              line-height: 14px;
              .pointer;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              i {
                display: block;
                width: 16px;
                height: 16px;
                background-size: contain !important;
                background: url('~@/assets/icons/_tmp/caps-fill.png') no-repeat;
              }
              span {
                margin-left: 10px;
              }
            }
          }
          .accessory-list {
            //margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            .card {
              &:active {
                color: #575757;
              }
              .pointer;
              margin-right: 18px;
              margin-top: 20px;
              width: 208px;
              height: 56px;
              border-radius: 8px;
              background: #ffffff;
              box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
              overflow: hidden;
              display: flex;
              color: #575757 !important;
              .card-left {
                height: 100%;
                background: linear-gradient(
                  270deg,
                  #00b4ff 0%,
                  #00b4ff 0%,
                  #1dd0d5 100%,
                  #1dd0d5 100%
                );
                width: 23%;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                  display: flex;
                  height: 32px;
                  width: 32px;
                  background-size: contain;
                  background-repeat: no-repeat;
                }
                .i-type-7z {
                  background: url('~@/assets/icons/_tmp/file-7Z.png');
                }
                .i-type-doc {
                  background: url('~@/assets/icons/_tmp/file-DOC.png');
                }
                .i-type-jpg {
                  background: url('~@/assets/icons/_tmp/file-JPG.png');
                }
                .i-type-png {
                  background: url('~@/assets/icons/_tmp/file-PNG.png');
                }
                .i-type-ppt {
                  background: url('~@/assets/icons/_tmp/file-PPT.png');
                }
                .i-type-txt {
                  background: url('~@/assets/icons/_tmp/file-TXT.png');
                }
                .i-type-xls {
                  background: url('~@/assets/icons/_tmp/file-XLS.png');
                }
                .i-type-xlsx {
                  background: url('~@/assets/icons/_tmp/file-XLS.png');
                }
                .i-type-zip {
                  background: url('~@/assets/icons/_tmp/file-ZIP.png');
                }
              }
              .card-right {
                padding: 10px;
                .tit {
                  font-size: 14px;
                  line-height: 14px;
                  word-break: break-all;
                  .more-text-ellipsis(1);
                }
                .size {
                  margin-top: 10px;
                  font-size: 12px;
                  line-height: 12px;
                  color: #9c9c9c;
                }
              }
            }
          }
        }
        .amount-status {
          margin-top: 40px;
          padding: 16px 40px;
          border-radius: 8px;
          background: #f4faff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            display: flex;
            .price {
              margin-left: 20px;
              font-size: 16px;
              strong {
                font-weight: bold;
              }
            }
          }
          .right {
            font-size: 14px;
            color: #575757;
            .btn-group {
              display: flex;
              .cannot,
              .pass,
              .reward {
                width: 120px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                border-radius: 20px;
                box-sizing: border-box;
                .pointer;
              }
              .cannot {
                border: 1px solid #00b4ff;
                color: #00b4ff;
              }
              .pass,
              .reward {
                background: #00b4ff;
                color: #fff;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
    .pass-log {
      margin-top: 40px;
      .log-title {
        height: 44px;
        line-height: 44px;
        padding-left: 40px;
        border-radius: 8px;
        background: #f8f8f8;
      }
      .log-list-wrapper {
        .log-item {
          border-radius: 8px;
          padding: 20px 40px;
          .row_1 {
            display: flex;
            align-items: center;
            .line_1 {
              //width: 200px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .time {
              }
              .avatar {
                margin-left: 50px;
                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                }
              }
            }
            .line_2 {
              padding-left: 20px;
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .info {
                .phone {
                  margin-left: 10px;
                }
              }
              .status {
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
          .row_2 {
            display: flex;
            .line_1 {
              width: 200px;
            }
            .line_2 {
              padding-left: 20px;
              flex: 1;
              .comment {
                line-height: 24px;
                font-size: 14px;
              }
            }
          }
        }
        .hirer-reply {
          background: #f8f8f8;
        }
      }
    }
  }
  /deep/ .el-dialog__header {
    text-align: center;
    .el-dialog__title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .modal-two-content {
    /deep/ .pass {
      line-height: 30px;
    }
    /deep/ .reward {
      text-align: center;
      p {
        font-size: 16px;
        line-height: 30px;
      }
      .price {
        margin-top: 40px;
        span {
          vertical-align: sub;
          font-size: 24px;
          font-weight: bold;
          line-height: 36px;
          color: #00b4ff;
          margin-left: 10px;
        }
      }
    }
    .cannot-pass {
      textarea {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        height: 158px;
        border-radius: 8px;
        background: #f4faff;
        border: none;
        resize: none;
        outline: none;
        border: 1px solid #f4faff;
        &:hover {
          border: 1px solid #00b4ff;
        }
        &:focus {
          border: 1px solid #00b4ff;
        }
      }
    }
  }
  .modal-two-btn {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      .pointer;
      width: 180px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
    }
    .cancel {
      border: 1px solid #dcdddd;
    }
    .confirm {
      background: linear-gradient(270deg, #00b4ff 0%, #00b4ff 0%, #1dd0d5 100%, #1dd0d5 100%);
      color: #fff;
    }
  }
</style>
